<template>
  <div>
    <div class="section">
      <div class="container w800">
        <empty
          title="Adjust your account settings here"
          btTo=""
          img="https://mir-s3-cdn-cf.behance.net/project_modules/disp_still/9f6465159728551.63a456b253488.gif"
          btLabel="Explore new AIs"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

export default {
  name: "readmeEditor",
  components: {},
  props: {
    bot: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //readmeJson: defaultReadme, // "432432432",
    };
  },
};
</script>

<style></style>
